.App {
  text-align: center;
}

.App-main {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(4px + 2vmin);
}

.header {
  font-size: 2em;
  font-weight: bold;
}

.intro {
  font-family: 'Reem Kufi', sans-serif;
  font-size: 2.75em;
  font-weight: 600;
  margin-left: 0.5em;
  margin-right: 0.5em;
  text-align: center;
}

.tagline {
  font-size: 1.5rem;
  margin: .5rem 0;
  font-weight: 400;
  margin-left: 1.5em;
  margin-right: 1.5em;
  margin-top: 0.2em;
  margin-bottom: 0.9em;
  text-align: center;
}

.about-me {
  font-size: 1rem;
  font-weight: 400;
  word-wrap: normal;
  margin-left: 2.5em;
  margin-right: 2.5em; 
  margin-bottom: 1.2em;
  text-align: center;
  width: 50%;
}

.icons-social i {
  padding: 10px;
  font-size: 1.5em;
}

.typed-cursor{
  opacity: 1;
  animation: typedjsBlink 5s infinite;
  -webkit-animation: typedjsBlink 5s infinite;
  animation: typedjsBlink 5s infinite;
}

.typed-cursor.typed-cursor--blink {
	animation: typedjsBlink 1.5s infinite!important;
}

img {
  width: 10em;
}


.change-mode {
  position: absolute;
  top: 25px;
  right: 25px;
}

@media (min-width: 576px) {
  html {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 992px) {
  html {
    font-size: 18px;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 20px;
  }
}

.full-bg-image {
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
}

.full-bg-image .App-main {
  color: #fffafa;
}

.full-bg-image .icons-social a {
  color: #fffafa;
}

.full-bg-image .icons-social a svg path {
  fill: #fffafa;
}
